import { PaginatedGroupSettings as PaginatedGroupSettingsApi } from "../API";
import { Workspace } from "./files";

export enum DisplayType {
    Row = 'row',
    Column = 'column',
}

export interface User {
    //id: string;
    //userId?: string | undefined;
    email: string;
    firstName?: string;
    lastName?: string;
    username: string;
    group: string;
    createdAt?: Date | undefined;
    updatedAt?: Date | undefined;
}

export enum PreviewHighlightAction {
    Comment = "comment",
    Dashboard = "dashboard"
}

export enum SaveDashboardItemType {
    Page = "page",
    Text = "text",
    ScreenCapture = "capture"
}

export enum ShareType {
    Internal = 'internal',
    External = 'external'
}

export interface GroupSettings {
    group: string;
    statuses?: string[];
    investmentStages?: string[];
    tags?: string[];
    shouldSyncAllDashboardsExternally?: boolean;
    shouldSendDigestEmail?: boolean;
    workspaces?: Workspace[],
}

export type PaginatedGroupSettings = Omit<PaginatedGroupSettingsApi, '__typename'>;