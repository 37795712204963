import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Container, Divider, Stack, Tab, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import GridViewIcon from '@mui/icons-material/GridView';
import TuneIcon from "@mui/icons-material/Tune";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { TabContext, TabList } from "@mui/lab";
import classnames from "classnames";
import storage from "../../utils/storage";
import DashboardSettingsModal from "../modals/dashboards-overview/DashboardSettingsModal";
import DashboardTagModal from "../modals/dashboards-overview/DashboardTagModal";
import configuredAsyncComponentLoader, { FallbackLoading } from "../templates/loader";
import DashboardsSearchOrCreate from "../molecules/dashboards-search-create/DashboardsSearchOrCreate";
import { useHistory } from "react-router-dom";
import UnassignedContentsBadge from "../atoms/UnassignedContentsBadge";
import BulkActionBadge from "../atoms/BulkActionBadge";
import {DashboardsContext} from "../../contexts/DashboardsContext";
import theme from "../../theme";
import { DashboardContext } from "../../contexts/DashboardContext";
import AddEditCompanyModal from "../modals/companies/AddEditCompanyModal";

const DashboardsView = configuredAsyncComponentLoader(
    () => import('../organisms/dashboards/DashboardsView'),
    { fallback: <FallbackLoading height={`calc(100vh - 250px)`} />}
);

export type LayoutTypes = 'list' | 'sort' | 'grid';

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        height: '100%',
        maxWidth: 'unset !important',
        overflowY: 'hidden',
    },
    inputField: {
        "& .MuiOutlinedInput-root": {
            minWidth: '120px',
            height: '50px',
            borderRadius: 20,
            "& > input": {
                fontSize: 16,
            },
            "&:hover fieldset": {
              borderColor: 'darkgray !important',
            },
            "&.Mui-focused fieldset": {
              borderColor: 'black !important',
            },
        },
    },
    mainLabel: {
        fontFamily: 'Inter',
        fontSize: '2rem',
        fontWeight: 700,
    },
    layoutIcons: {
        minWidth: '52px',
        height: '48px',
        borderRadius: '4px',
    },
    selectedIcon: {
        backgroundColor: '#007681',
    },
    layoutIcon: {
        height: 20,
        width: 20,
    },
    createButton: {
        width: 'auto',
        height: 40,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    signUpButton: {
        width: '100%',
        height: 40,
        borderRadius: 20,
        backgroundImage: 'linear-gradient(to right, #3ABDC6 0%, #6FEEF6  15%, #3ABDC6  50%, #6FEEF6  80%, #3ABDC6 100%)',
        backgroundSize: '105% auto',
        boxShadow: '2px 2px 4px #9CCED3, -1px -1px 3px #fff',
        color: 'white',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
}));

const DashboardsPage: React.FC<{}> = () => {
    const classes = useStyles();
    const history = useHistory();
    const { dashboards, setWorkspace } = useContext(DashboardsContext);
    const { setPublicView } = useContext(DashboardContext);

    const [layout, setLayout] = useState<LayoutTypes>('list');
    const [addCompanyModalOpen, setAddCompanyModalOpen] = useState<boolean>(false);
    const [manageTagsOpenModal, setManageTagsOpenModal] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");

    const setPersistedLayout = (type: LayoutTypes) => {
        storage.setItem('config.dashboards-layout', type);
        setLayout(type);
    }

    useEffect(() => {
        setWorkspace(null);
        setPublicView(false);
        setPersistedLayout(storage.getItem('config.dashboards-layout') || 'list');
    // eslint-disable-next-line
    }, []);

    return (<>
        <Container className={classes.rootContainer}>
            <Stack direction="column" spacing={1} pt={4}>
                <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" width="100%">
                    <Typography className={classes.mainLabel} fontSize="2rem">
                        {'All companies'}
                    </Typography>
                    {!!dashboards.length && (<>
                        <Box width="600px">
                            <DashboardsSearchOrCreate search create
                                onSearch={(value) => setSearch(value)}
                                onCreate={(dashboard) => history.push(`/dashboards/${dashboard.id}`)}/>
                        </Box>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <BulkActionBadge />
                            <Stack className={classes.layoutIcons} justifyContent="center">
                                <DashboardSettingsModal onOpenManageTags={() => setManageTagsOpenModal(true)} />
                            </Stack>
                            <TabContext value={layout}>
                                <TabList onChange={(_, value) => setPersistedLayout(value)} sx={{"& .MuiTabs-indicator": { display: 'none', }}}>
                                    <Tab className={classnames(classes.layoutIcons, layout === 'list' && classes.selectedIcon)}
                                        icon={<FormatListBulletedIcon className={classes.layoutIcon}
                                            sx={{ color: layout === 'list' ? 'white' : theme.colors.primary['700'] }} />}
                                        value="list" />
                                    <Tab className={classnames(classes.layoutIcons, layout === 'sort' && classes.selectedIcon)}
                                        icon={<TuneIcon className={classes.layoutIcon}
                                            sx={{ color: layout === 'sort' ? 'white' : theme.colors.primary['700'] }} />}
                                        value="sort" />
                                    <Tab className={classnames(classes.layoutIcons, layout === 'grid' && classes.selectedIcon)}
                                        icon={<GridViewIcon className={classes.layoutIcon}
                                            sx={{ color: layout === 'grid' ? 'white' : theme.colors.primary['700'] }} />}
                                        value="grid" />
                                </TabList>
                            </TabContext>
                            <UnassignedContentsBadge />
                            <Box ml="32px !important" />
                            <Button variant="outlined"
                                className={classes.createButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setAddCompanyModalOpen(true);
                                }}
                            >{'+ New company'} </Button>
                        </Stack>
                    </>)}
                </Stack>
                <Divider />
                <DashboardsView layout={layout} search={search} />
            </Stack>
        </Container>
        {addCompanyModalOpen && (
            <AddEditCompanyModal
                isOpen={addCompanyModalOpen}
                onClose={() => setAddCompanyModalOpen(false)}
            />
        )}
        <DashboardTagModal
            open={manageTagsOpenModal}
            onClose={() => setManageTagsOpenModal(false)}
            forManagement
        />
    </>);
}

export default DashboardsPage;